import React from "react";
import { PageHeader, Menu } from "antd";

// displays a page header

export default function Header() {
  return (
    <a href="." rel="noopener noreferrer">
      <PageHeader
        avatar={{ src:'https://pbs.twimg.com/profile_images/1428378928830246916/EtOUs3p4_400x400.jpg'}}

        title="CRYPTOCHIMP MEMES"
        style={{ cursor: "pointer" }}
      />

      <Menu style={{ textAlign:"center", backgroundColor:"#FFF" }} mode="horizontal">
          <Menu.Item>
              <a href="./active">
                ACTIVE
              </a>
          </Menu.Item>
          <Menu.Item>
              <a rel="noopener noreferrer" href="./past">
                PAST
              </a>
          </Menu.Item>
      </Menu>
    </a>

  );
}
