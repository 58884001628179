import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import "antd/dist/antd.css";
import {  JsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import {  LinkOutlined } from "@ant-design/icons"
import "./App.css";
import {Row, Col, Button, Statistic, Typography, Space, Menu, Alert, Input, List, Steps, Card, Modal, InputNumber, Divider, Collapse} from "antd";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useUserAddress } from "eth-hooks";
import { format, formatDistanceToNow, subDays, formatRelative, formatDistance, intervalToDuration } from "date-fns";
import { addYears, formatWithOptions } from 'date-fns/fp'
import { useExchangePrice, useGasPrice, useUserProvider, useContractLoader, useContractReader, useEventListener, useBalance, useExternalContractLoader } from "./hooks";
import { Header, Account, Faucet, Ramp, Contract, GasGauge, Address, AddressInput, ThemeSwitch } from "./components";
import { Transactor } from "./helpers";
import { formatEther, parseEther } from "@ethersproject/units";
import { utils, constants } from "ethers";
//import Hints from "./Hints";
import { Hints, ExampleUI, Subgraph } from "./views"
import { useThemeSwitcher } from "react-css-theme-switcher";
import { INFURA_ID, DAI_ADDRESS, DAI_ABI, NETWORK, NETWORKS } from "./constants";
import StackGrid from "react-stack-grid";
import ReactJson from 'react-json-view'
import assets from './assets.js'

const { BufferList } = require('bl')
// https://www.npmjs.com/package/ipfs-http-client
const ipfsAPI = require('ipfs-http-client');
const ipfs = ipfsAPI({host: 'ipfs.infura.io', port: '5001', protocol: 'https' })
const { Panel } = Collapse;
const { Step } = Steps;

const { Title } = Typography;

console.log("📦 Assets: ",assets)

/*
    Welcome to 🏗 scaffold-eth !

    Code:
    https://github.com/austintgriffith/scaffold-eth

    Support:
    https://t.me/joinchat/KByvmRe5wkR-8F_zz6AjpA
    or DM @austingriffith on twitter or telegram

    You should get your own Infura.io ID and put it in `constants.js`
    (this is your connection to the main Ethereum network for ENS etc.)


    🌏 EXTERNAL CONTRACTS:
    You can also bring in contract artifacts in `constants.js`
    (and then use the `useExternalContractLoader()` hook!)
*/


/// 📡 What chain are your contracts deployed to?
const targetNetwork = NETWORKS['rinkeby']; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)

// 😬 Sorry for all the console logging
const DEBUG = false
const USE_LOCAL_PROVIDER = false

//EXAMPLE STARTING JSON:
const STARTING_JSON = {
  "description": "It's actually a bison?",
  "external_url": "https://austingriffith.com/portfolio/paintings/",// <-- this can link to a page for the specific file too
  "image": "https://austingriffith.com/images/paintings/buffalo.jpg",
  "name": "Buffalo",
  "attributes": [
     {
       "trait_type": "BackgroundColor",
       "value": "green"
     },
     {
       "trait_type": "Eyes",
       "value": "googly"
     }
  ]
}

//helper function to "Get" from IPFS
// you usually go content.toString() after this...
const getFromIPFS = async hashToGet => {
  for await (const file of ipfs.get(hashToGet)) {
    console.log(file.path)
    if (!file.content) continue;
    const content = new BufferList()
    for await (const chunk of file.content) {
      content.append(chunk)
    }
    console.log(content)
    return content
  }
}

// 🛰 providers
if(DEBUG) console.log("📡 Connecting to Mainnet Ethereum");
// const mainnetProvider = getDefaultProvider("mainnet", { infura: INFURA_ID, etherscan: ETHERSCAN_KEY, quorum: 1 });
// const mainnetProvider = new InfuraProvider("mainnet",INFURA_ID);
//
// attempt to connect to our own scaffold eth rpc and if that fails fall back to infura...
const scaffoldEthProvider = new JsonRpcProvider("https://rpc.scaffoldeth.io:48544")
const mainnetInfura = new JsonRpcProvider("https://mainnet.infura.io/v3/" + INFURA_ID)
// ( ⚠️ Getting "failed to meet quorum" errors? Check your INFURA_I

// 🏠 Your local provider is usually pointed at your local blockchain
const localProviderUrl = targetNetwork.rpcUrl;

// as you deploy to other networks you can set REACT_APP_PROVIDER=https://dai.poa.network in packages/react-app/.env
const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : localProviderUrl;
if(DEBUG) console.log("🏠 Connecting to provider:", localProviderUrlFromEnv);
let localProvider
if(USE_LOCAL_PROVIDER) localProvider = new JsonRpcProvider(localProviderUrlFromEnv);


// 🔭 block explorer URL
const blockExplorer = targetNetwork.blockExplorer;


function App(props) {

  const mainnetProvider = (scaffoldEthProvider && scaffoldEthProvider._network) ? scaffoldEthProvider : mainnetInfura
  if(DEBUG) console.log("🌎 mainnetProvider",mainnetProvider)

  const [injectedProvider, setInjectedProvider] = useState();
  /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  const price = useExchangePrice(targetNetwork,mainnetProvider);

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(targetNetwork,"fast");
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.

  // use localProvider, use this if USE_LOCAL_PROVIDER==true
  // const userProvider = useUserProvider(injectedProvider, localProvider);
  // use web3 only, use if USE_LOCAL_PROVIDER==false
  const userProvider = useUserProvider(injectedProvider, localProvider);
  if(!USE_LOCAL_PROVIDER) localProvider = userProvider;
  const address = useUserAddress(userProvider);

  if(DEBUG) console.log("👩‍💼 selected address:",address)

  // You can warn the user if you would like them to be on a specific network
  let localChainId = localProvider && localProvider._network && localProvider._network.chainId
  if(DEBUG) console.log("🏠 localChainId",localChainId)

  let selectedChainId = userProvider && userProvider._network && userProvider._network.chainId
  if(DEBUG) console.log("🕵🏻‍♂️ selectedChainId:",selectedChainId)

  // For more hooks, check out 🔗eth-hooks at: https://www.npmjs.com/package/eth-hooks

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userProvider, gasPrice)

  // Faucet Tx can be used to send funds from the faucet
  const faucetTx = Transactor(localProvider, gasPrice)

  // 🏗 scaffold-eth is full of handy hooks like this one to get your balance:
  const yourLocalBalance = useBalance(localProvider, address);
  if(DEBUG) console.log("💵 yourLocalBalance",yourLocalBalance?formatEther(yourLocalBalance):"...")

  // Just plug in different 🛰 providers to get your balance on different chains:
  const yourMainnetBalance = useBalance(mainnetProvider, address);
  if(DEBUG) console.log("💵 yourMainnetBalance",yourMainnetBalance?formatEther(yourMainnetBalance):"...")

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider)
  if(DEBUG) console.log("📝 readContracts",readContracts)

  // If you want to make 🔐 write transactions to your contracts, use the userProvider:
  const writeContracts = useContractLoader(userProvider)
  if(DEBUG) console.log("🔐 writeContracts",writeContracts)

  // EXTERNAL CONTRACT EXAMPLE:
  //
  // If you want to bring in the mainnet DAI contract it would look like:
  const mainnetDAIContract = useExternalContractLoader(mainnetProvider, DAI_ADDRESS, DAI_ABI)
  if (DEBUG) console.log("🌍 DAI contract on mainnet:",mainnetDAIContract)
  //
  // Then read your DAI balance like:
  const myMainnetDAIBalance = useContractReader({DAI: mainnetDAIContract},"DAI", "balanceOf",["0x34aA3F359A9D614239015126635CE7732c18fDF3"])
  if (DEBUG)console.log("🥇 myMainnetDAIBalance:",myMainnetDAIBalance)


  // keep track of a variable from the contract in the local React state:
  const balance = useContractReader(readContracts,"YourCollectible", "balanceOf", [ address ])
  if (DEBUG) console.log("🤗 balance:",balance)

  //📟 Listen for broadcast events
  const transferEvents = useEventListener(readContracts, "YourCollectible", "Transfer", localProvider, 1);
  if (DEBUG) console.log("📟 Transfer events:",transferEvents)


  const [modalVisible, setModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [auctionDetails, setAuctionDetails] = useState({price: "", duration: ""});
  const [auctionToken, setAuctionToken] = useState("");
  const [viewAuctionToken, setViewAuctionToken] = useState("");

  //
  // 🧠 This effect will update yourCollectibles by polling when your balance changes
  //
  const yourBalance = balance && balance.toNumber && balance.toNumber()
  const [ yourCollectibles, setYourCollectibles ] = useState()

  // useEffect(()=>{
  //   const updateYourCollectibles = async () => {
  //     let collectibleUpdate = []
  //     for(let tokenIndex=0;tokenIndex<balance;tokenIndex++){
  //       try{
  //         console.log("GEtting token index",tokenIndex)
  //         const tokenId = await readContracts.YourCollectible.tokenOfOwnerByIndex(address, tokenIndex)
  //         console.log("tokenId",tokenId)
  //         const tokenURI = await readContracts.YourCollectible.tokenURI(tokenId)
  //         console.log("tokenURI",tokenURI)
  //
  //         const ipfsHash =  tokenURI.replace("https://ipfs.io/ipfs/","")
  //         console.log("ipfsHash",ipfsHash)
  //
  //         const jsonManifestBuffer = await getFromIPFS(ipfsHash)
  //
  //         try{
  //           const jsonManifest = JSON.parse(jsonManifestBuffer.toString())
  //           // console.log("jsonManifest",jsonManifest)
  //           collectibleUpdate.push({ id:tokenId, uri:tokenURI, owner: address, ...jsonManifest })
  //         }catch(e){console.log(e)}
  //
  //       }catch(e){console.log(e)}
  //     }
  //     setYourCollectibles(collectibleUpdate)
  //   }
  //   updateYourCollectibles()
  // },[ address, yourBalance ])

  /*
  const addressFromENS = useResolveName(mainnetProvider, "austingriffith.eth");
  console.log("🏷 Resolved austingriffith.eth as:",addressFromENS)
  */


  // let networkDisplay = ""
  // if(localChainId && selectedChainId && localChainId != selectedChainId ){
  //   networkDisplay = (
  //     <div style={{zIndex:2, position:'absolute', right:0,top:60,padding:16}}>
  //       <Alert
  //         message={"⚠️ Wrong Network"}
  //         description={(
  //           <div>
  //             You have <b>{NETWORK(selectedChainId).name}</b> selected and you need to be on <b>{NETWORK(localChainId).name}</b>.
  //           </div>
  //         )}
  //         type="error"
  //         closable={false}
  //       />
  //     </div>
  //   )
  // }else{
  //   networkDisplay = (
  //     <div style={{zIndex:-1, position:'absolute', right:154,top:28,padding:16,color:targetNetwork.color}}>
  //       {targetNetwork.name}
  //     </div>
  //   )
  // }

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new Web3Provider(provider));
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const [route, setRoute] = useState();
  useEffect(() => {
    setRoute(window.location.pathname)
  }, [setRoute]);

  let faucetHint = ""
  const faucetAvailable = localProvider && localProvider.connection && localProvider.connection.url && localProvider.connection.url.indexOf(window.location.hostname)>=0 && !process.env.REACT_APP_PROVIDER && price > 1;

  const [ faucetClicked, setFaucetClicked ] = useState( false );
  if(!faucetClicked&&localProvider&&localProvider._network&&localProvider._network.chainId==31337&&yourLocalBalance&&formatEther(yourLocalBalance)<=0){
    faucetHint = (
      <div style={{padding:16}}>
        <Button type={"primary"} onClick={()=>{
          faucetTx({
            to: address,
            value: parseEther("0.01"),
          });
          setFaucetClicked(true)
        }}>
          💰 Grab funds from the faucet ⛽️
        </Button>
      </div>
    )
  }


  const [ yourJSON, setYourJSON ] = useState( STARTING_JSON );
  const [ sending, setSending ] = useState()
  const [ ipfsHash, setIpfsHash ] = useState()
  const [ ipfsDownHash, setIpfsDownHash ] = useState()

  const [ downloading, setDownloading ] = useState()
  const [ ipfsContent, setIpfsContent ] = useState()
  const [yourBid, setYourBid] = useState({});

  const [ transferToAddresses, setTransferToAddresses ] = useState({})

  const [ loadedAssets, setLoadedAssets ] = useState()
  const [ mintedAssets, setMintedAssets ] = useState()

  const updateYourCollectibles = async () => {
    let assetUpdate = []
    let mintedAssetUpdate = []
    for(let a in assets){
      try{
        const forSale = await readContracts.YourCollectible.forSale(utils.id(a))
        let owner
        let auctionInfo
        if(!forSale){
          const tokenId = await readContracts.YourCollectible.uriToTokenId(utils.id(a))
          owner = await readContracts.YourCollectible.ownerOf(tokenId)
          const nftAddress = readContracts.YourCollectible.address;
          auctionInfo = await readContracts.Auction.getTokenAuctionDetails(nftAddress, tokenId);
          // if forSale property is set to false, then item has been minted
          mintedAssetUpdate.push({id:a,...assets[a],forSale:forSale,owner:owner, auctionInfo})
        }


        assetUpdate.push({id:a,...assets[a],forSale:forSale,owner:owner, auctionInfo})
      }catch(e){console.log(e)}
    }
    setLoadedAssets(assetUpdate)
    setMintedAssets(mintedAssetUpdate)
  }
  useEffect(()=>{
    if(readContracts && readContracts.YourCollectible) updateYourCollectibles()
  }, [ assets, readContracts, transferEvents ]);

  const startAuction = (tokenUri) => {
    return async () => {
      setAuctionToken(tokenUri);
      setModalVisible(true);
    }
  }

  const placeBid = async (tokenUri, ethAmount) => {
    const tokenId = await readContracts.YourCollectible.uriToTokenId(utils.id(tokenUri));
    const nftAddress = readContracts.YourCollectible.address;
    await tx( writeContracts.Auction.bid(nftAddress, tokenId, {
      value: parseEther(ethAmount.toString())
    }));
    updateYourCollectibles();
  }

  const completeAuction = (tokenUri) => {
    return async () => {
      const tokenId = await readContracts.YourCollectible.uriToTokenId(utils.id(tokenUri));
      const nftAddress = readContracts.YourCollectible.address;
      await tx(writeContracts.Auction.executeSale(nftAddress, tokenId));
      updateYourCollectibles();
    }
  }

  const cancelAuction = (tokenUri) => {
    return async () => {
      const tokenId = await readContracts.YourCollectible.uriToTokenId(utils.id(tokenUri));
      const nftAddress = readContracts.YourCollectible.address;
      await tx(writeContracts.Auction.cancelAuction(nftAddress, tokenId));
      updateYourCollectibles();
    }
  }

  let galleryList = []
  let liveGalleryList = []
  let pastGalleryList = []
  let homePage = []

  homePage.push(
        <>
        {/*<Row gutter={[32, 32]} key={loadedAssets[a].name} style={{backgroundImage:`url(${mintedAssets[a].image})`}}>*/}
        <Row gutter={[32, 32]}>
          <Col span={24} style={{textAlign:"left"}}>
            <Card>
              <div style={{fontSize:80, textAlign:"center"}}>
                CryptoChimp Memes:
              </div>
              <div style={{fontSize:60, textAlign:"center"}}>
                The Debut Collection
              </div>
              <div>
                <img style={{marginLeft: "auto", marginRight: "auto", display: "flex", alignItems: "center", marginTop: "20px"}} src="https://pbs.twimg.com/profile_images/1428378928830246916/EtOUs3p4_400x400.jpg"/>
              </div>
              <br/>
              <div style={{fontSize:24, textAlign:"left", zIndex:999}}>
              </div>
              <div>
                
              </div>
              <br/>
          </Card> <br/>

          <Card title="FAQ">


          <Collapse defaultActiveKey={['1', '2', '3']}>
          <Panel header="What is this?" key="1">
            <p>A debut collection of NFTs by CryptoChimp </p>
          </Panel>
          <Panel header="How does it work?" key="2">
            <p>All NFTs are 1/1. The auction expires at a certain date, and then the contract transfers the NFT to the highest bidder. All other bids are returned to their original wallets.</p>
          </Panel>
          <Panel header="What are the required network settings?" key="3">
            <p>Connect app to your Metamask wallet on Ethereum Mainnet in order to place bids.</p>
          </Panel>
          </Collapse>  
          </Card>  
          </Col>
        </Row>
        <br/> <br/>
        </>
    )

  for (let a in mintedAssets) {
    let cardActions = []
    let auctionDetails = [];
    const { auctionInfo } = mintedAssets[a];
    const {Countdown} = Statistic;

    const deadline = new Date(auctionInfo.duration * 1000);
    const countdownDeadline = new Date() + new Date(auctionInfo.duration * 1000);

    const duration = intervalToDuration({start: new Date(), end: deadline})
    const durationDays = duration.days
    const isEnded = deadline <= new Date();

      cardActions.push(
        <div>
          {!mintedAssets[a].auctionInfo.isActive && address === mintedAssets[a].owner && <><Button style={{ marginBottom: "10px" }} onClick={startAuction(mintedAssets[a].id)} disabled={address !== mintedAssets[a].owner}>Start auction</Button><br/></>}
          {mintedAssets[a].auctionInfo.isActive && address === mintedAssets[a].auctionInfo.seller && <><Button style={{ marginBottom: "10px" }} onClick={completeAuction(mintedAssets[a].id)}>Complete auction</Button><br/></>}
          {mintedAssets[a].auctionInfo.isActive && address === mintedAssets[a].auctionInfo.seller && <><Button style={{ marginBottom: "10px" }} onClick={cancelAuction(mintedAssets[a].id)}>Cancel auction</Button><br/></>}
        </div>
      )

      auctionDetails.push(auctionInfo.isActive ? (
          <div style={{ marginTop: "20px" }}>
            <Card>
              <Countdown
                  title="AUCTION ENDING IN"
                  value = {deadline}
                  format = "D [days], H [hours], m [minutes], s [seconds]"
                  valueStyle={{ color: '#000', fontSize:"22px", fontWeight:"bold" }}
              />
                <br/>
            {/*<p style={{ marginTop: 0 }}>{!isEnded ? `Auction ends ${formatDistanceToNow(deadline, { addSuffix: true })}, at ${format(deadline, "MMMM dd, hh:mm")}` : 'Auction has already ended'}</p>*/}
              {auctionInfo.maxBidUser === constants.AddressZero ? "Highest bid was not made yet" : <Statistic title={"CURRENT BID"} value={String(utils.formatEther(auctionInfo.maxBid))} valueStyle={{ color: '#000', fontSize:"22px", fontWeight:"bold" }} suffix={"ETH"} prefix={<Address address={auctionInfo.maxBidUser} ensProvider={mainnetProvider} blockExplorer={blockExplorer} minimized={true} />}/>}
            </Card>
            <br/>
            <div>

            <Card title="PLACE YOUR BID" style={{fontSize:14, textAlign:"left"}}>
                {/*<p style={{margin:0, marginRight: "15px"}}>Bid Amount (ETH): </p>*/}

                <InputNumber style={{minWidth:"300px"}} placeholder="BID AMOUNT (ETH)" min={0} value={yourBid[mintedAssets[a].id]} onChange={newBid => setYourBid({...yourBid, [mintedAssets[a].id]: newBid})} />
              <Button type="primary" shape="round" size="large" style={{marginTop: "7px"}} onClick={() => placeBid(mintedAssets[a].id, yourBid[mintedAssets[a].id])}>Place bid</Button>
            </Card>

            </div>
          </div>
      ) : null);

  // if auction is not active, then push to past tab
  if (!mintedAssets[a].auctionInfo.isActive) {
      pastGalleryList.push(
        <>
        {/*<Row gutter={[32, 32]} key={loadedAssets[a].name} style={{backgroundImage:`url(${mintedAssets[a].image})`}}>*/}
        <Row gutter={[32, 32]} key={mintedAssets[a].name}>
          <Col span={12} style={{textAlign:"left"}}>
            <Card>
              <div style={{fontSize:80, textAlign:"left"}}>
                {mintedAssets[a].name}
                {/*<a style={{cursor:"pointer",opacity:0.33}} href={mintedAssets[a].external_url} target="_blank"><LinkOutlined /></a>*/}
              </div>
                <br/>
              <div style={{fontSize:24, textAlign:"left", zIndex:999}}>
                {mintedAssets[a].description}
              </div>
              <div>
                owned by: <Address
                  address={mintedAssets[a].owner}
                  ensProvider={mainnetProvider}
                  blockExplorer={blockExplorer}
                  minimized={true}
                />
              </div>
              <br/>
              {auctionDetails}
              {cardActions}
          </Card>  
          </Col>
          <Col span={12}>
            <video src={loadedAssets[a].image} style={{maxWidth: "100%", objectFit: "contain"}} controls/>
          </Col>
        </Row>
        <br/> <br/>
        </>
    )
  // auction is active, so push to live tab  
  } else {
    liveGalleryList.push(
        <>
        {/*<Row gutter={[32, 32]} key={loadedAssets[a].name} style={{backgroundImage:`url(${loadedAssets[a].image})`}}>*/}
        <Row gutter={[32, 32]} key={loadedAssets[a].name} style={{marginTop:"132px"}}>
          <Col span={12} style={{textAlign:"left"}}>
              {/*<div style={{fontSize:80, textAlign:"left"}}>*/}
                <span style={{fontSize:"86px", fontWeight:"bold"}}>{loadedAssets[a].name}</span>
                {/*<a style={{cursor:"pointer",opacity:0.33}} href={loadedAssets[a].external_url} target="_blank"><LinkOutlined /></a>*/}
              {/*</div>*/}
              <br/>
              <br/>
              {auctionDetails}
              {cardActions}
          </Col>
          <Col span={12}>
              <video src={loadedAssets[a].image} style={{maxWidth: "100%", objectFit: "contain", boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px"}} controls/>
              <br/> <br/> <br/>
              <Card title="DESCRIPTION" style={{fontSize:14, textAlign:"left"}}>
                <div style={{fontSize:14, textAlign:"left", zIndex:999}}>
                  {mintedAssets[a].description}
                </div>
              </Card>
          </Col>
        </Row>
        </>
    )
  }
}

  // for(let a in (loadedAssets ? loadedAssets.slice(0, 6) : [])){
  for (let a in loadedAssets) {
    // console.log("loadedAssets",a,loadedAssets[a])

    let cardActions = []
    let auctionDetails = [];
    if(loadedAssets[a].forSale){
      cardActions.push(
        <div>
          <Button type="primary" shape="round" size="large" onClick={()=>{
            // console.log("gasPrice,",gasPrice)
            tx( writeContracts.YourCollectible.mintItem(loadedAssets[a].id,{gasPrice:gasPrice}) )
          }}>
            Mint
          </Button>
        </div>
      )
      auctionDetails.push(null)
    }else{
      const { auctionInfo } = loadedAssets[a];
      const deadline = new Date(auctionInfo.duration * 1000);
      const isEnded = deadline <= new Date();

      cardActions.push(
        <div style={{fontSize:16, fontWeight:"bold"}}>
          <Divider orientation={"left"} style={{borderTopColor:"#95a5a6"}}>Owned By
            <span>  </span>
            <Address
              address={loadedAssets[a].owner}
              ensProvider={mainnetProvider}
              blockExplorer={blockExplorer}
              minimized={true}
            />
          </Divider>
          {!loadedAssets[a].auctionInfo.isActive && address === utils.getAddress("0x1657eea2692cc4a24935ceFa63Bb0a7d9742c35F") && <><Button style={{ marginBottom: "10px" }} shape={"round"} onClick={startAuction(loadedAssets[a].id)} disabled={address !== loadedAssets[a].owner}>Start Auction</Button><br/></>}
          {loadedAssets[a].auctionInfo.isActive && address === loadedAssets[a].auctionInfo.seller && <><Button shape={"round"} style={{ marginBottom: "10px" }} onClick={completeAuction(loadedAssets[a].id)}>Complete auction</Button></>}
            <span>    </span>
          {loadedAssets[a].auctionInfo.isActive && address === loadedAssets[a].auctionInfo.seller && <><Button shape={"round"} style={{ marginBottom: "10px" }} onClick={cancelAuction(loadedAssets[a].id)}>Cancel auction</Button><br/></>}
        </div>
      )

      auctionDetails.push(auctionInfo.isActive ? (
          <div style={{ marginTop: "20px" }}>
            <Divider orientation={"left"} style={{borderTopColor:"#95a5a6"}}>{!isEnded ? `Auction in progress` : `Auction has ended`}</Divider>
            <Steps size="small" current={!isEnded ? 0 : 1}>
              <Step title="In Progress" />
              <Step title="Finished" />
            </Steps>
            <p style={{ margin: 0, marginBottom: "2px"}}>Minimum price is {utils.formatEther(auctionInfo.price)} ETH</p>
            <p style={{ marginTop: 0 }}>{!isEnded ? `Auction ends ${formatDistanceToNow(deadline, { addSuffix: true })}, at ${format(deadline, "MMMM dd, hh:mm")}` : 'Auction has already ended'}</p>
            <div>
              {auctionInfo.maxBidUser === constants.AddressZero ? "Highest bid was not made yet" : <div><Statistic title="Highest Bid" value={utils.formatEther(auctionInfo.maxBid)} loading />Highest bid by: <Address
                  address={auctionInfo.maxBidUser}
                  ensProvider={mainnetProvider}
                  blockExplorer={blockExplorer}
                  minimized={true}
              /><p>{utils.formatEther(auctionInfo.maxBid)} ETH</p></div>}
            </div>

            <div>
            <div style={{display: "flex", alignItems: "center", marginTop: "20px", width:350}}>
              <p style={{margin:0, marginRight: "15px"}}>Your bid in ETH: </p>
              <InputNumber placeholder="0.1" value={yourBid[loadedAssets[a].id]} onChange={newBid => setYourBid({...yourBid, [loadedAssets[a].id]: newBid})} style={{ flexGrow: 1 }}/>
            </div>
              <Button shape={"round"} style={{marginTop: "7px"}} onClick={() => placeBid(loadedAssets[a].id, yourBid[loadedAssets[a].id])} disabled={!yourBid[loadedAssets[a].id] || isEnded}>Place bid</Button>
            </div>
              <br/>
          </div>
      ) : null);
    }

    galleryList.push(
        <>
        {/*<Row gutter={[32, 32]} key={loadedAssets[a].name} style={{backgroundImage:`url(${loadedAssets[a].image})`}}>*/}
        <Row gutter={[32, 32]} key={loadedAssets[a].name}>
          <Col span={12} style={{textAlign:"left"}}>
            <Card>
              <div style={{fontSize:80, textAlign:"left"}}>
                {loadedAssets[a].name}
                {/*<a style={{cursor:"pointer",opacity:0.33}} href={loadedAssets[a].external_url} target="_blank"><LinkOutlined /></a>*/}
              </div>
                <br/>
              <div style={{fontSize:24, textAlign:"left", zIndex:999}}>
                {loadedAssets[a].description}
              </div>
              <br/>
              {auctionDetails}
              {cardActions}
            </Card>
          </Col>
          <Col span={12}>
            <video src={loadedAssets[a].image} style={{maxWidth: "100%", objectFit: "contain", boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px"}} controls/>
            {/*<img src={loadedAssets[a].image} style={{maxWidth: "100%", objectFit: "contain"}}/>*/}
          </Col>
        </Row>
        </>
    )
  }


  const handleOk = async () => {
    setModalVisible(false);
    const { price, duration } = auctionDetails;
    const tokenId = await readContracts.YourCollectible.uriToTokenId(utils.id(auctionToken));

    const auctionAddress = readContracts.Auction.address;
    const nftAddress = readContracts.YourCollectible.address;
    await writeContracts.YourCollectible.approve(auctionAddress, tokenId);

    const ethPrice = utils.parseEther(price.toString());
    const blockDuration = Math.floor(new Date().getTime() / 1000) + duration;

    await tx(writeContracts.Auction.createTokenAuction(nftAddress, tokenId, ethPrice, blockDuration, { gasPrice }));

    const auctionInfo = await readContracts.Auction.getTokenAuctionDetails(nftAddress, tokenId);
    console.log('auctionInfo', { auctionInfo });
  }

  const handleCancel = () => {
    setModalVisible(false);
  }

  return (
    <div className="App">

      <Modal title="Start auction" visible={modalVisible} onOk={handleOk} onCancel={handleCancel} okButtonProps={{ disabled: !auctionDetails.price || !auctionDetails.duration }} okText="Start">
        <div style={{display: "flex", alignItems: "center"}}>
          <p style={{margin:0, marginRight: "15px"}}>ETH price (minimal bid): </p>
          <InputNumber placeholder="0.1" value={auctionDetails.price} onChange={newPrice => setAuctionDetails({...auctionDetails, price: newPrice})} style={{ flexGrow: 1 }}/>
        </div>
        <br/>
        <div style={{display: "flex", alignItems: "center"}}>
          <p style={{margin:0, marginRight: "15px"}}>Duration in seconds: </p>
          <InputNumber placeholder="3600" value={auctionDetails.duration} onChange={newDuration => setAuctionDetails({...auctionDetails, duration: newDuration})} style={{ flexGrow: 1 }}/>
        </div>
      </Modal>

      {/* ✏️ Edit the header and change the title to your project name */}
      <Header />
      {/*{networkDisplay}*/}

      <BrowserRouter>
        

        <Switch>
          <Route exact path="/">
            <div style={{ maxWidth:1024, margin: "auto", marginTop:32, paddingBottom:56, textAlign:"center" }}>
                {homePage}
            </div>

          </Route>

          <Route path="/gallery">
            {/*
                🎛 this scaffolding is full of commonly used components
                this <Contract/> component will automatically parse your ABI
                and give you a form to interact with it locally
            */}

            <div style={{ maxWidth:1024, margin: "auto", marginTop:32, paddingBottom:56, textAlign:"center" }}>
              {/*<Button disabled={galleryList.length === 0} onClick={updateYourCollectibles} style={{marginBottom: "25px"}}>Update collectibles</Button>*/}

              {/*<StackGrid*/}
              {/*  columnWidth={300}*/}
              {/*  gutterWidth={16}*/}
              {/*  gutterHeight={16}*/}
              {/*>*/}
                {galleryList}
              {/*</StackGrid>*/}
            </div>

          </Route>

          <Route exact path="/past">
            <div style={{ maxWidth:1024, margin: "auto", marginTop:32, paddingBottom:56, textAlign:"center" }}>
                {pastGalleryList}
            </div>
          </Route>

          <Route exact path="/active">
            <div style={{ maxWidth:1024, margin: "auto", marginTop:32, paddingBottom:56, textAlign:"center" }}>
                {liveGalleryList}
            </div>
          </Route>


          {/*<Route path="/transfers">*/}
          {/*  <div style={{ width:600, margin: "auto", marginTop:32, paddingBottom:32 }}>*/}
          {/*    <List*/}
          {/*      bordered*/}
          {/*      dataSource={transferEvents}*/}
          {/*      renderItem={(item) => {*/}
          {/*        return (*/}
          {/*          <List.Item key={item[0]+"_"+item[1]+"_"+item.blockNumber+"_"+item[2].toNumber()}>*/}
          {/*            <span style={{fontSize:16, marginRight:8}}>#{item[2].toNumber()}</span>*/}
          {/*            <Address*/}
          {/*                address={item[0]}*/}
          {/*                ensProvider={mainnetProvider}*/}
          {/*                fontSize={16}*/}
          {/*            /> =>*/}
          {/*            <Address*/}
          {/*                address={item[1]}*/}
          {/*                ensProvider={mainnetProvider}*/}
          {/*                fontSize={16}*/}
          {/*            />*/}
          {/*          </List.Item>*/}
          {/*        )*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</Route>*/}

          {/*<Route path="/ipfsup">*/}
          {/*  <div style={{ paddingTop:32, width:740, margin:"auto", textAlign:"left" }}>*/}
          {/*    <ReactJson*/}
          {/*      style={{ padding:8 }}*/}
          {/*      src={yourJSON}*/}
          {/*      theme={"pop"}*/}
          {/*      enableClipboard={false}*/}
          {/*      onEdit={(edit,a)=>{*/}
          {/*        setYourJSON(edit.updated_src)*/}
          {/*      }}*/}
          {/*      onAdd={(add,a)=>{*/}
          {/*        setYourJSON(add.updated_src)*/}
          {/*      }}*/}
          {/*      onDelete={(del,a)=>{*/}
          {/*        setYourJSON(del.updated_src)*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </div>*/}

          {/*  <Button style={{margin:8}} loading={sending} size="large" shape="round" type="primary" onClick={async()=>{*/}
          {/*      console.log("UPLOADING...",yourJSON)*/}
          {/*      setSending(true)*/}
          {/*      setIpfsHash()*/}
          {/*      const result = await ipfs.add(JSON.stringify(yourJSON))//addToIPFS(JSON.stringify(yourJSON))*/}
          {/*      if(result && result.path) {*/}
          {/*        setIpfsHash(result.path)*/}
          {/*      }*/}
          {/*      setSending(false)*/}
          {/*      console.log("RESULT:",result)*/}
          {/*  }}>Upload to IPFS</Button>*/}

          {/*  <div  style={{padding:16,paddingBottom:150}}>*/}
          {/*    {ipfsHash}*/}
          {/*  </div>*/}

          {/*</Route>*/}
          {/*<Route path="/ipfsdown">*/}
          {/*    <div style={{ paddingTop:32, width:740, margin:"auto" }}>*/}
          {/*      <Input*/}
          {/*        value={ipfsDownHash}*/}
          {/*        placeHolder={"IPFS hash (like QmadqNw8zkdrrwdtPFK1pLi8PPxmkQ4pDJXY8ozHtz6tZq)"}*/}
          {/*        onChange={(e)=>{*/}
          {/*          setIpfsDownHash(e.target.value)*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*    <Button style={{margin:8}} loading={sending} size="large" shape="round" type="primary" onClick={async()=>{*/}
          {/*        console.log("DOWNLOADING...",ipfsDownHash)*/}
          {/*        setDownloading(true)*/}
          {/*        setIpfsContent()*/}
          {/*        const result = await getFromIPFS(ipfsDownHash)//addToIPFS(JSON.stringify(yourJSON))*/}
          {/*        if(result && result.toString) {*/}
          {/*          setIpfsContent(result.toString())*/}
          {/*        }*/}
          {/*        setDownloading(false)*/}
          {/*    }}>Download from IPFS</Button>*/}

          {/*    <pre  style={{padding:16, width:500, margin:"auto",paddingBottom:150}}>*/}
          {/*      {ipfsContent}*/}
          {/*    </pre>*/}
          {/*</Route>*/}
          {/*<Route path="/debugcontracts">*/}
          {/*    <Contract*/}
          {/*      name="YourCollectible"*/}
          {/*      signer={userProvider && userProvider.getSigner()}*/}
          {/*      provider={localProvider}*/}
          {/*      address={address}*/}
          {/*      blockExplorer={blockExplorer}*/}
          {/*    />*/}
          {/*</Route>*/}
        </Switch>
      </BrowserRouter>

      {/*<ThemeSwitch />*/}


      {/* 👨‍💼 Your account is in the top right with a wallet at connect options */}
      <div style={{ position: "fixed", textAlign: "right", right: 0, top: 0, padding: 10 }}>
         <Account
           address={address}
           localProvider={localProvider}
           userProvider={userProvider}
           mainnetProvider={mainnetProvider}
           price={price}
           web3Modal={web3Modal}
           loadWeb3Modal={loadWeb3Modal}
           logoutOfWeb3Modal={logoutOfWeb3Modal}
           blockExplorer={blockExplorer}
         />
         {faucetHint}
      </div>

      {/* 🗺 Extra UI like gas price, eth price, faucet, and support: */}
      {/* <div style={{ position: "fixed", textAlign: "left", left: 0, bottom: 20, padding: 10 }}>*/}
      {/*   <Row align="middle" gutter={[4, 4]}>*/}
      {/*     <Col span={8}>*/}
      {/*       <Ramp price={price} address={address} networks={NETWORKS}/>*/}
      {/*     </Col>*/}

      {/*     <Col span={8} style={{ textAlign: "center", opacity: 0.8 }}>*/}
      {/*       <GasGauge gasPrice={gasPrice} />*/}
      {/*     </Col>*/}
      {/*     <Col span={8} style={{ textAlign: "center", opacity: 1 }}>*/}
      {/*       <Button*/}
      {/*         onClick={() => {*/}
      {/*           window.open("https://t.me/joinchat/KByvmRe5wkR-8F_zz6AjpA");*/}
      {/*         }}*/}
      {/*         size="large"*/}
      {/*         shape="round"*/}
      {/*       >*/}
      {/*         <span style={{ marginRight: 8 }} role="img" aria-label="support">*/}
      {/*           💬*/}
      {/*         </span>*/}
      {/*         Support*/}
      {/*       </Button>*/}
      {/*     </Col>*/}
      {/*   </Row>*/}

      {/*   <Row align="middle" gutter={[4, 4]}>*/}
      {/*     <Col span={24}>*/}
      {/*       {*/}
      {         /*  if the local provider has a signer, let's show the faucet:  */}
      {/*         faucetAvailable ? (*/}
      {/*           <Faucet localProvider={localProvider} price={price} ensProvider={mainnetProvider}/>*/}
      {/*         ) : (*/}
      {/*           ""*/}
      {/*         )*/}
      {/*       }*/}
      {/*     </Col>*/}
      {/*   </Row>*/}
      {/* </div>*/}

    </div>
  );
}


/*
  Web3 modal helps us "connect" external wallets:
*/
const web3Modal = new Web3Modal({
  // network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: INFURA_ID,
      },
    },
  },
});

const logoutOfWeb3Modal = async () => {
  await web3Modal.clearCachedProvider();
  setTimeout(() => {
    window.location.reload();
  }, 1);
};

 window.ethereum && window.ethereum.on('chainChanged', chainId => {
  setTimeout(() => {
    window.location.reload();
  }, 1);
})

export default App;